import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { AnyObjectSchema, InferType } from 'yup';

export const validateAndAssert = async <S extends AnyObjectSchema>(
  res: Promise<AxiosResponse<unknown>>,
  schema: S
): Promise<InferType<S>> => {
  const { data } = await res;

  try {
    const result = await schema.validate(data);
    return result;
  } catch (e) {
    console.log(
      `%cDTO ERROR: ${get(e, 'errors')}`,
      'display: inline-block ; border: 3px solid red ; border-radius: 7px ; font-size: 20px ; ' +
        'padding: 10px ; margin: 20px ;'
    );
    toast.error('Server DTO Validation Error (check console for details)');
    throw e;
  }
};

export const validate = async <S extends AnyObjectSchema, R>(res: Promise<AxiosResponse<R>>, schema: S): Promise<R> => {
  const { data } = await res;

  try {
    const isValid = await schema.isValid(data);

    if (isValid) {
      return data;
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(
      `%cDTO ERROR: ${get(e, 'errors')}`,
      'display: inline-block ; border: 3px solid red ; border-radius: 7px ; font-size: 20px ; ' +
        'padding: 10px ; margin: 20px ;'
    );
    toast.error('Server DTO Validation Error (check console for details)');
    throw e;
  }
};
