import { mapValues } from 'lodash';

import { parseNumbersFromString } from '@app/utils/string.utils';

import { PaletteMode, ThemeOptions } from '@mui/material';

import { tailwindTheme } from './tailwind-theme-generated';

type CustomTypographyVariantsKeys = keyof typeof tailwindTheme.fontSize;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    '2xl': true;
  }

  interface TypographyVariants extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<CustomTypographyVariantsKeys, true> {}
}

const mapFontSizes = () => {
  const mapped = mapValues(tailwindTheme.fontSize, (fontSize: [string, { lineHeight: string }]) => ({
    fontSize: fontSize[0],
    lineHeight: fontSize[1].lineHeight,
  }));

  return mapped as unknown as Record<CustomTypographyVariantsKeys, React.CSSProperties>;
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: tailwindTheme.colors.primary,
          },
          text: {
            primary: tailwindTheme.colors.zinc[900],
            secondary: tailwindTheme.colors.zinc[400],
          },
        }
      : {
          // palette values for dark mode

          primary: {
            main: tailwindTheme.colors.primary,
          },
          background: {
            paper: tailwindTheme.colors.zinc[900],
          },
          text: {
            primary: tailwindTheme.colors.white,
            secondary: tailwindTheme.colors.zinc[400],
          },
        }),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: parseNumbersFromString(tailwindTheme.screens.sm),
      md: parseNumbersFromString(tailwindTheme.screens.md),
      lg: parseNumbersFromString(tailwindTheme.screens.lg),
      xl: parseNumbersFromString(tailwindTheme.screens.xl),
      '2xl': parseNumbersFromString(tailwindTheme.screens['2xl']),
    },
  },
  typography: {
    fontFamily: tailwindTheme.fontFamily.sans.join(','),
    ...mapFontSizes(),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          xl: 'h4',
          xxl: 'h3',
          xxxl: 'h2',
        },
      },
    },
  },
});
