import { useState, useMemo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { useHandler } from '@app/hooks/useHandler.hook';
import { ObjectValues } from '@app/utils/type.utils';
import { clsxm } from '@app/styles/clsxm';
import React from 'react';
import { IS_PROD_ENV } from '@app/environment/typed-env';

const Portal: React.FC<{
  target?: Element;
}> = ({ children, target = document.body }) => {
  return ReactDOM.createPortal(children, target);
};

const noPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

// more symbols at https://github.com/facebook/react/blob/d48dbb824985166ecb7b2959db03090a8593dce0/packages/shared/ReactSymbols.js
const REACT_FORWARD_REF_TYPE = Symbol.for('react.forward_ref');
const checkIsForwardRef = (object: any) => {
  if (typeof object !== 'object') {
    return false;
  }
  return object.$$typeof === REACT_FORWARD_REF_TYPE;
};

const asEnum = (
  value: string[]
): {
  type: 'enum';
  value: string[];
} => ({
  type: 'enum',
  value,
});

const asBoolean = (
  defaultValue?: boolean
): {
  type: 'boolean';
  defaultValue?: boolean;
} => ({
  type: 'boolean',
  defaultValue,
});

const asString = (
  defaultValue?: string
): {
  type: 'string';
  defaultValue?: string;
} => ({
  type: 'string',
  defaultValue,
});

const allTypersMapper = {
  asEnum,
  asBoolean,
  asString,
};

type AllTypers = ReturnType<ObjectValues<typeof allTypersMapper>>;

type Config = {
  name: string;
  disabled?: boolean;
  debugProps?: (typers: typeof allTypersMapper) => Record<string, AllTypers>;
};

const EMPTY_OVERRIDE = {};

const SimplePopover: React.FC<{
  title: string;
}> = ({ children, title }) => {
  const [show, setShow] = useState(false);

  return (
    <span className="relative" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} aria-hidden>
      {show && <div className="absolute -top-6 left-0 flex whitespace-nowrap bg-white ring-1">{title}</div>}
      {children}
    </span>
  );
};

export const withQA = <V extends React.FC<any>>(config: Config, Component: V): V => {
  if (config.disabled || IS_PROD_ENV) {
    return Component;
  }
  const isForwardRef = checkIsForwardRef(Component);

  const debugProps: Record<string, AllTypers> = config.debugProps?.(allTypersMapper) || {};

  // TODO forwardRef if component is forwardRef as well
  const DebugProxy: React.FC<any> = React.forwardRef((props, outerRef) => {
    const [ref, setRef] = useState<HTMLElement | null>(null);
    const [override, setOverride] = useState<any>(EMPTY_OVERRIDE);
    const [isOpen, setIsOpen] = useState(false);
    const [altKey, setAltKey] = useState(false);

    const handleRef = useHandler((newRef: HTMLElement | null) => {
      // TODO debug this case
      if (newRef === null || newRef instanceof HTMLElement) {
        setRef(newRef);
      }
      if (outerRef) {
        if (typeof outerRef === 'function') {
          outerRef(newRef);
        } else {
          outerRef.current = newRef;
        }
      }
    });

    const { pos, dot } = useMemo(() => {
      // include altKey to recalculate client rect
      void altKey;
      if (!ref) {
        return {};
      }
      //   console.log('ref', typeof ref, ref);
      const rect = ref.getBoundingClientRect();
      return {
        pos: {
          left: rect.left - 320 + window.scrollX,
          top: rect.top - 80 + window.scrollY,
        },
        dot: {
          left: rect.left - 20 + window.scrollX,
          top: rect.top + window.scrollY,
        },
      };
    }, [ref, altKey]);

    useEffect(() => {
      const handleKey = (e: KeyboardEvent) => {
        // key pair: option + . = ≥
        if (e.altKey && e.key === '≥') {
          setAltKey((cur) => !cur);
        }
      };
      window.addEventListener('keydown', handleKey);
      return () => {
        window.removeEventListener('keydown', handleKey);
      };
    }, []);

    const [pulse, setPulse] = useState<React.CSSProperties | undefined>();

    const maybeFocus = useHandler(() => {
      if (!ref) {
        return;
      }
      const rect = ref.getBoundingClientRect();
      const style: React.CSSProperties = {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        width: rect.width,
        height: rect.height,
      };
      setPulse(style);
      setTimeout(() => {
        setPulse(undefined);
      }, 250);
    });

    const dragRef = useRef<HTMLDivElement | null>(null);

    const keysForIteration = useMemo(() => {
      return [...new Set([...Object.keys(debugProps), ...Object.keys(props)])];
    }, [props]);

    const isOverriden = override !== EMPTY_OVERRIDE;

    return (
      <>
        <Portal>
          {pulse && (
            <div className="absolute z-[2000] animate-pulse rounded bg-primary/50 ring-4 duration-200" style={pulse} />
          )}
          {altKey && !isOpen && (
            <div
              className={clsxm(
                'absolute h-4 w-4 cursor-pointer rounded-full',
                isOverriden ? 'bg-yellow-500' : 'bg-zinc-700'
              )}
              style={dot}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(true);
              }}
              onMouseDown={noPropagation}
              onFocus={noPropagation}
              aria-hidden
            />
          )}
          {isOpen && (
            // TODO fix user-select outside component while dragging
            <Draggable
              handle=".drag-handle"
              bounds="parent"
              onMouseDown={noPropagation}
              onDrag={noPropagation}
              nodeRef={dragRef}
            >
              <div
                ref={dragRef}
                className="absolute z-[4321] min-w-[320px] bg-white"
                style={pos}
                onClick={noPropagation}
                onMouseDown={noPropagation}
                onFocus={noPropagation}
                aria-hidden
              >
                {/* top border */}
                <div className="drag-handle h-4 cursor-move bg-lime-200" />
                <div className="flex items-stretch">
                  {/* left border */}
                  <div className="drag-handle w-4 cursor-move bg-lime-200" />
                  {/* content */}
                  <div className="p-2">
                    <div className="text-center font-bold">
                      {config.name}
                      <button onClick={maybeFocus} className="ml-2 rounded px-2 ring-1">
                        focus
                      </button>
                    </div>
                    <div className="divide-y-2 divide-lime-200">
                      {keysForIteration.map((key, i) => {
                        const existsOriginal = key in props;
                        const originalValue = props[key];
                        let editValue = key in override ? override[key] : originalValue;
                        let isBoolean = typeof editValue === 'boolean';
                        let isString = typeof editValue === 'string';
                        let options: string[] = [];
                        let isModified = editValue !== originalValue;
                        const typer = debugProps[key] || {};
                        if (typer.type === 'enum') {
                          options = typer.value;
                        } else if (typer.type === 'boolean') {
                          isBoolean = true;
                          editValue = editValue ?? typer.defaultValue;
                          if (!existsOriginal) {
                            isModified = editValue !== typer.defaultValue;
                          }
                        } else if (typer.type === 'string') {
                          isString = true;
                          editValue = editValue ?? typer.defaultValue;
                          if (!existsOriginal) {
                            isModified = editValue !== typer.defaultValue;
                          }
                        }
                        const isObject = typeof editValue === 'object';
                        const isFunction = typeof editValue === 'function';
                        if (isFunction) {
                          return null;
                        }
                        return (
                          <div key={i} className="py-2">
                            <SimplePopover title={`original: ${originalValue}`}>{key}</SimplePopover>:{' '}
                            {!Boolean(options.length) && isString && (
                              <input
                                type="text"
                                className="ring-1"
                                value={editValue}
                                onChange={(e) =>
                                  setOverride((curr: any) => {
                                    return {
                                      ...curr,
                                      [key]: e.target.value,
                                    };
                                  })
                                }
                              />
                            )}
                            {Boolean(options.length) && (
                              <select
                                onChange={(e) =>
                                  setOverride((curr: any) => {
                                    return {
                                      ...curr,
                                      [key]: e.target.value,
                                    };
                                  })
                                }
                                value={editValue}
                              >
                                {options.map((e, i) => (
                                  <option value={e} key={i}>
                                    {e}
                                  </option>
                                ))}
                              </select>
                            )}
                            {isBoolean && (
                              <input
                                type="checkbox"
                                className="ring-1"
                                checked={editValue}
                                onChange={() =>
                                  setOverride((curr: any) => {
                                    return {
                                      ...curr,
                                      [key]: !editValue,
                                    };
                                  })
                                }
                              />
                            )}
                            {key === 'children' && isObject && (
                              <button
                                className="rounded bg-primary px-2"
                                onClick={() =>
                                  setOverride((curr: any) => {
                                    return {
                                      ...curr,
                                      [key]: String(editValue),
                                    };
                                  })
                                }
                              >
                                stringify
                              </button>
                            )}
                            {isModified && (
                              <button
                                className="rounded bg-primary px-2"
                                onClick={() =>
                                  setOverride((curr: any) => {
                                    return {
                                      ...curr,
                                      [key]: originalValue,
                                    };
                                  })
                                }
                              >
                                reset
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                      <button className="rounded bg-primary px-2" onClick={() => setOverride(EMPTY_OVERRIDE)}>
                        reset
                      </button>
                      <button
                        className="rounded bg-primary px-2"
                        onClick={() => {
                          setOverride(EMPTY_OVERRIDE);
                          setIsOpen(false);
                        }}
                      >
                        reset & close
                      </button>
                      <button className="rounded bg-primary px-2" onClick={() => setIsOpen(false)}>
                        hide
                      </button>
                      <button
                        className="rounded bg-primary px-2"
                        onClick={() => {
                          const c = { props, override, setOverride, Component, ref };
                          (window as any).c = c;
                          console.log('window.c = ', c);
                        }}
                      >
                        log
                      </button>
                    </div>
                  </div>
                  {/* right border */}
                  <div className="drag-handle w-4 cursor-move bg-lime-200" />
                </div>
                {/* bottom border */}
                <div className="drag-handle h-4 cursor-move bg-lime-200" />
              </div>
            </Draggable>
          )}
        </Portal>
        {isForwardRef ? (
          <Component {...props} {...override} ref={handleRef} />
        ) : (
          <div ref={handleRef}>
            <Component {...props} {...override} />
          </div>
        )}
      </>
    );
  });
  return DebugProxy as any;
};
