import { camelCase } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils';

import App from './App';
import '@app/styles/index.scss';

ClassNameGenerator.configure((componentName) => camelCase(componentName.replace('Mui', '')));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
