import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RootLayout } from '@app/modules/RootLayout';
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params';
import { DialogRenderComponent } from '@app/services/dialog-manager';

import { ThemeWrapper, ResponsiveManager } from '@app/hoc';

import 'react-toastify/dist/ReactToastify.css';
import { RouteQueryParamsAdapter } from './RouteAdapter';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App() {
  return (
    <Router>
      <ThemeWrapper>
        <QueryParamProvider
          ReactRouterRoute={RouteQueryParamsAdapter as unknown as React.FunctionComponent}
          stringifyOptions={queryStringifyOptions}
        >
          <QueryClientProvider client={queryClient}>
            <ResponsiveManager>
              <DialogRenderComponent />
              <RootLayout />
            </ResponsiveManager>
          </QueryClientProvider>
        </QueryParamProvider>
        <ToastContainer />
      </ThemeWrapper>
    </Router>
  );
}

export default App;
