import { TypedListener } from './typed-listener';
import { ReactDVFC, OmitedProps, OpenDialogEntityType } from './types';

class DialogManager {
  private readonly onChangeEventListener = new TypedListener<OpenDialogEntityType[]>();
  openDialogs: OpenDialogEntityType[] = [];

  close = (CName: string) => {
    this.openDialogs = this.openDialogs.map((d) => {
      if (CName === d.name) {
        return { ...d, isOpen: false };
      }
      return d;
    });
    this.onChangeEventListener.emit(this.openDialogs);
  };

  closeAll = () => {
    this.openDialogs = this.openDialogs.map((d) => {
      return { ...d, isOpen: false };
    });
    this.onChangeEventListener.emit(this.openDialogs);
  };

  closeOthers = (CName: string) => {
    this.openDialogs = this.openDialogs.map((d) => {
      if (CName !== d.name) {
        return { ...d, isOpen: false };
      }
      return d;
    });
    this.onChangeEventListener.emit(this.openDialogs);
  };

  createDialog = <T>(component: ReactDVFC<T>) => {
    const CName = component.displayName || component.name;
    return {
      open: (
        props: Omit<React.ComponentPropsWithoutRef<typeof component>, OmitedProps>,
        actionType: 'push' | 'replace' = 'replace'
      ) => {
        if (actionType === 'replace') {
          this.openDialogs = [{ name: CName, isOpen: true, component, props }];
          this.onChangeEventListener.emit(this.openDialogs);
          return;
        }
        const findDialog = this.openDialogs.find((d) => d.name === CName);
        if (findDialog) {
          this.openDialogs = this.openDialogs.map((d) => {
            if (d.name === findDialog.name) {
              return { name: d.name, isOpen: true, component, props };
            }
            return d;
          });
        } else {
          this.openDialogs = [...this.openDialogs, { name: CName, isOpen: true, component, props }];
        }
        this.onChangeEventListener.emit(this.openDialogs);
      },

      close: () => this.close(CName),
      closeOthers: () => this.closeOthers(CName),
      closeAll: () => this.closeAll(),
      component,
    };
  };

  on = this.onChangeEventListener.on;
  off = this.onChangeEventListener.off;
}

export const manager = new DialogManager();
