// error that indicates concurrency issue - async flow is not latest anymore because new async flow started
export class StaleAsyncHandlerError extends Error {
  readonly isStale = true;
}

export const createLatestAsyncHandler =
  (loadId = 0) =>
  async <T>(promiseOrFn: Promise<T> | (() => Promise<T>)): Promise<T> => {
    const expectedLoadId = ++loadId;
    const res = await (typeof promiseOrFn === 'function' ? promiseOrFn() : promiseOrFn);
    if (expectedLoadId !== loadId) {
      throw new StaleAsyncHandlerError();
    }
    return res;
  };

type LatestAsyncHandler = ReturnType<typeof createLatestAsyncHandler>;

/**
 * mapping Map<globalKey, LatestAsyncHandler>
 */
const asyncMap = new Map<string | symbol, LatestAsyncHandler>();

type LatestAsyncResult<T> =
  | {
      staleError: boolean;
      error: unknown;
      result?: undefined;
    }
  | {
      result: T;
      staleError: false;
      error?: undefined;
    };

export const takeLatestAsync = async <T>(
  promiseOrFn: Promise<T> | (() => Promise<T>),
  globalKey: string | symbol
): Promise<LatestAsyncResult<T>> => {
  let latestHandler = asyncMap.get(globalKey);
  if (!latestHandler) {
    latestHandler = createLatestAsyncHandler();
    asyncMap.set(globalKey, latestHandler);
  }
  try {
    const result = await latestHandler(promiseOrFn);
    return {
      result,
      staleError: false,
    };
  } catch (error) {
    const staleError = error instanceof StaleAsyncHandlerError;
    return { staleError, error: error || new Error('unknown') };
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
