import { useBasicQueryControls } from '@app/hooks/useBasicQueryControls.hook';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Button, Table, TableColumn } from '@app/components';
import { NestedKeyOf } from '@app/utils/type.utils';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/fotmats';
import { ActionLogOutDto } from '@app/swagger-types';
import { buildSortParam } from '@app/utils/api.util';
import {
  useGetActionLogs,
  useGetGoogleSheets,
  useGetGoogleSheetUrl,
} from '@app/domain/action-logs/action-logs.api.hook';
import { TruncatedTypography } from '@app/components/truncated-typography/TruncatedTypography';

const getEntityField = (v: NestedKeyOf<ActionLogOutDto>) => v;

export const Dashboard = () => {
  const {
    values: { page, sort = buildSortParam<ActionLogOutDto>('createdDate', 'desc'), size },
    handlers: { handlePageChange, handleSortChange, handleSizeChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: 10,
    },
  });

  const { data, isFetching, refetch } = useGetActionLogs(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size,
    },
    { keepPreviousData: true, staleTime: 3000 }
  );

  const { data: googleSheetUrl } = useGetGoogleSheetUrl({ keepPreviousData: true });

  const { mutate: getGoogleSheets, isLoading } = useGetGoogleSheets({
    onSuccess: () => {
      refetch();
    },
  });

  const columns: TableColumn<ActionLogOutDto>[] = useMemo(() => {
    return [
      {
        headerName: 'Executed Date',
        field: getEntityField('createdDate'),
        align: 'left',
        render: ({ createdDate }) => (
          <Typography variant="xs">{format(new Date(createdDate), FORMAT.DATETIME_FORMAT)}</Typography>
        ),
      },
      {
        headerName: 'New Transactions',
        field: getEntityField('newTransactions'),
        align: 'right',
        render: ({ newTransactions }) => <Typography variant="xs">{newTransactions}</Typography>,
      },
      {
        headerName: 'Status',
        field: getEntityField('status'),
        align: 'center',
        render: ({ status }) => <Typography variant="xs">{status}</Typography>,
      },
      {
        headerName: 'Error Message',
        field: getEntityField('status'),
        align: 'left',
        render: ({ errorMessage }) => (
          <TruncatedTypography variant="xs" className="text-rose-500">
            {errorMessage}
          </TruncatedTypography>
        ),
      },
      {
        headerName: 'Executed By',
        field: getEntityField('createdBy'),
        align: 'right',
        render: ({ createdBy }) => (
          <Typography variant="xs">{createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : 'System'}</Typography>
        ),
      },
    ];
  }, []);

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography variant="xl" className="font-bold">
          Action Logs ({data?.total || 0})
        </Typography>
      </div>
    );
  }, [data?.total]);

  const rightContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4 sm:flex-row">
        {googleSheetUrl && (
          <a href={googleSheetUrl} target="_blank" rel="noreferrer">
            <Button variant="contained" color="primary">
              Go to Google Sheet
            </Button>
          </a>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => getGoogleSheets()}
          loading={isLoading}
          disabled={isFetching}
        >
          Load
        </Button>
      </div>
    );
  }, [getGoogleSheets, isLoading, isFetching, googleSheetUrl]);

  return (
    <Table
      cols={columns}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightContent}
      notFoundMessage="No logs"
      currentPage={page}
      pageSize={size}
      onSizeChange={handleSizeChange}
      tableData={data?.result || []}
      loading={isFetching}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      wrapperClassName="w-full"
    />
  );
};
