import { useMount } from 'react-use';

import { AuthStatus } from '@app/auth/api/auth.type';

import { useStore } from '@app/store/useStore.hook';

import { RootRoutes } from './RootRoutes';
import { useFiltredRoutes } from '@app/router';

export const RootLayoutElements = () => {
  const elements = useFiltredRoutes(RootRoutes);

  return <>{elements}</>;
};

export const RootLayout: React.FC = () => {
  const { getMe, authStatus } = useStore(({ currentUser, getMe, authStatus }) => ({
    currentUser,
    getMe,
    authStatus,
  }));

  useMount(() => {
    getMe();
  });

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  return <RootLayoutElements />;
};
