import { FileApi } from '@app/api/file/file.api';

interface FileCache {
  [key: string]: string;
}

interface Pending {
  [key: string]: Promise<unknown>;
}

const PENDING: Pending = {};

const CACHE: FileCache = {};

export const memoizedImageBackgroundDownloader = (imageId: string) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const alreadyUrl = CACHE[imageId];

      if (alreadyUrl) {
        return resolve(alreadyUrl);
      }

      if (!!PENDING[imageId]) {
        PENDING[imageId].then(() => {
          delete PENDING[imageId];
          resolve(CACHE[imageId]);
        });
      } else {
        const fileRequest = FileApi.getFile({ id: imageId });

        PENDING[imageId] = fileRequest;

        const { data } = await fileRequest;

        const url = URL.createObjectURL(data);

        CACHE[imageId] = url;

        return resolve(url);
      }
    } catch (e) {
      reject(e);
    }
  });
};
