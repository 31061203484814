import styles from './NotFound.module.scss';
import clsx from 'clsx';

import { Typography } from '@mui/material';

// import { ReactComponent as NotFoundHappyImage } from '@assets/images/not-found-happy.svg';

interface PropTypes {
  className?: string;
  notFoundMessage?: string;
}

export const NotFound: React.FC<PropTypes> = ({ className, notFoundMessage = 'No data to show' }) => {
  return (
    <div className={clsx(styles.Wrapper, className)}>
      <div className={styles.NotFoundIcon}>{/* <NotFoundHappyImage /> */}</div>
      <Typography align="center" className={styles.Text} variant="h5">
        {notFoundMessage}
      </Typography>
    </div>
  );
};
