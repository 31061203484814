import clsx from 'clsx';
import { useMemo } from 'react';
import styles from './Header.module.scss';

import { useStore } from '@app/store/useStore.hook';

import { AppBar, Toolbar, Typography } from '@mui/material';

import { ReactComponent as Logo } from '@app/assets/images/app-logo.svg';
import { typedEnv } from '@app/environment/typed-env';
import { clsxm } from '@app/styles/clsxm';
import { UserHeaderInfo } from './user-header-info/UserHeaderInfo';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className, children }) => {
  const { currentUser: user } = useStore(({ currentUser, currentBreakpointDevice, navMobileOpen, setNavMobile }) => ({
    currentUser,
    currentBreakpointDevice,
    navMobileOpen,
    setNavMobile,
  }));

  const desktopHeaderContent = useMemo(() => {
    return (
      <>
        <div className={styles['left-group']}>
          <div className="flex items-center space-x-3">
            <Logo className="w-[33px] text-white" />
            <Typography variant="m" className="text-white">
              {typedEnv.REACT_APP_TITLE}
            </Typography>
          </div>
        </div>
        <div className={styles['right-group']}>
          {user && (
            <div className={clsxm(styles['user-info'], 'p-2')}>
              <UserHeaderInfo user={user} />
            </div>
          )}
        </div>
      </>
    );
  }, [user]);

  return (
    <AppBar
      position="fixed"
      className={clsx(styles.header, styles.desktop, className)}
      // className={clsx(styles.header, isDesktop ? styles.desktop : styles.mobile, className)}
      elevation={0}
    >
      <Toolbar className={clsxm(styles.toolbar, 'container mx-auto')}>
        {children ? children : desktopHeaderContent}
        {/*{children ? children : isDesktop ? desktopHeaderContent : mobileHeaderContent}*/}
      </Toolbar>
    </AppBar>
  );
};
