/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  message?: string;
}

export interface ErrorResponse {
  /** @format int64 */
  timestamp?: number;

  /** @format int32 */
  status?: number;
  errors?: Error[];
}

/**
 * @example COOKIE
 */
export enum EAuthType {
  COOKIE = 'COOKIE',
  TOKEN = 'TOKEN',
}

export interface SignInStepOneInDto {
  /** @example dberchiyan@osdb.io */
  email: string;

  /** @example dberchiyan@osdb.io */
  password: string;
  authType?: EAuthType;
}

export interface AuthOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: ERole;
  status: EUserStatus;
  accessToken?: string;
  refreshToken?: string;
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
}

export enum EUserStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
}

export interface UpdateTokensInDto {
  refreshToken: string;
}

export interface AuthTokensOutDto {
  accessToken: string;
  refreshToken: string;
}

export interface UserOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: ERole;
  status: EUserStatus;
}

export interface ActionLogOutDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  newTransactions: number;
  status: 'COMPLETED' | 'FAILED';
  errorMessage?: string;
  createdBy?: UserOutDto;

  /** @format date-time */
  createdDate: string;
}

export interface PageResponseActionLogOutDto {
  result: ActionLogOutDto[];

  /** @format int64 */
  total: number;
}
