import { FC } from 'react';
import { TooltipProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import { isString } from 'lodash';

interface Props extends TypographyProps {
  maxLength?: number;
  tooltipProps?: Omit<TooltipProps, 'title'>;
}

export const TruncatedTypography: FC<Props> = ({ children, maxLength = 50, tooltipProps = {}, ...props }) => {
  if (isString(children) && children.length > maxLength) {
    return (
      <Tooltip title={children} {...tooltipProps}>
        <Typography {...props}>{children.slice(0, maxLength)}...</Typography>
      </Tooltip>
    );
  }
  return <Typography {...props}>{children}</Typography>;
};
