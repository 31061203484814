import { useMemo, useEffect } from 'react';

import { DeviceBreakpointType } from '@app/store/main-ui/types';
import { useStore } from '@app/store/useStore.hook';

import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const ResponsiveManager: React.FC = ({ children }) => {
  const { currentBreakpointDevice, updateBreakpoint } = useStore(({ currentBreakpointDevice, updateBreakpoint }) => ({
    currentBreakpointDevice,
    updateBreakpoint,
  }));

  const isMobileMq = useMediaQuery(
    (theme: Theme) => {
      return theme.breakpoints.down('sm');
    },
    { noSsr: true }
  );
  const isTabletMq = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('lg');
  });

  const finalDeviceBreakpoint = useMemo(() => {
    // DETECT IF THIS IS DESKTOP
    if (!isMobileMq && !isTabletMq) {
      return DeviceBreakpointType.DESKTOP;
    }

    // DETECT IF THIS IS TABLET

    if (!isMobileMq && isTabletMq) {
      return DeviceBreakpointType.TABLET;
    }

    // DETECT IF THIS IS MOBILE

    if (isMobileMq) {
      return DeviceBreakpointType.PHONE;
    }

    return DeviceBreakpointType.UNKNOWN;
  }, [isMobileMq, isTabletMq]);

  useEffect(() => {
    if (finalDeviceBreakpoint !== currentBreakpointDevice.deviceType) {
      updateBreakpoint(finalDeviceBreakpoint);
    }
  }, [currentBreakpointDevice.deviceType, finalDeviceBreakpoint, updateBreakpoint]);

  if (currentBreakpointDevice.deviceType === DeviceBreakpointType.UNKNOWN) {
    return null;
  }
  return <>{children}</>;
};
