import { useForm, SubmitHandler } from 'react-hook-form';

import { SignInFormValidationSchema } from '@app/auth/api/auth.form';

import { useStore } from '@app/store/useStore.hook';

import { Typography } from '@mui/material';

import { AuthApi } from '@app/auth/api/auth.api';
import { Input, Button, Form } from '@app/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { maybeRenderError } from '@app/utils/maybeRenderError';

export const SignIn = () => {
  const setCurrentUser = useStore().setCurrentUser;

  const { control, formState, handleSubmit, register, clearErrors } = useForm<SignInFormValidationSchema>({
    resolver: yupResolver(SignInFormValidationSchema),
  });

  const [{ loading, error, value: result }, action] = useAsyncFn(async (formData: SignInFormValidationSchema) => {
    const { data } = await AuthApi.signIn({ ...formData });
    return data;
  }, []);

  useEffect(() => {
    if (!result) {
      return;
    }
    setCurrentUser(result);
  }, [result, setCurrentUser]);

  const onSubmit: SubmitHandler<SignInFormValidationSchema> = useCallback(
    async (formData) => {
      clearErrors();
      await action(formData);
    },
    [clearErrors, action]
  );

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: loading,
      };
    };
    return fn;
  }, [register, formState, loading]);

  return (
    <div className="space-y-4">
      <Typography variant="xxxl" className="mb-8 font-bold">
        Sign in to your account
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input {...inputRegister('email')} label="Email" placeholder="Email" id="email" data-cy="sign-in-email" />
        <Input
          {...inputRegister('password')}
          label="Password"
          placeholder="Password"
          type="password"
          id="password"
          data-cy="sign-in-password"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className="!mt-6"
          loading={loading}
          data-cy="sign-in-submit"
        >
          Sign In
        </Button>
        {maybeRenderError(error)}
      </Form>
    </div>
  );
};
