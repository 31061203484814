import { mixed, MixedSchema, AnyObject, string, lazy, object, array, number, AnyObjectSchema } from 'yup';

export const oneOfEnum = <T>(enumObject: { [s: string]: T } | ArrayLike<T>): MixedSchema<T, AnyObject, undefined, ''> =>
  mixed<T>().oneOf(Object.values(enumObject));

export const password = () => string().required('Required Field').min(6, 'Min 6 characters');
export const email = () => string().email('Invalid email address.').required('Required Field');

const lazyAny = lazy(() => mixed());

export type AnyLazyType = typeof lazyAny;

export const pageableData = <T extends AnyObjectSchema | AnyLazyType, V extends T['__outputType']>(data: T) => {
  return object({
    result: array().of<V>(data).required(),
    total: number().required(),
  });
};
