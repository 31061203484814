import { typedEnv } from '@app/environment/typed-env';
import { StoreSlice } from '../storeTypes';
import { MainUISlice, DeviceBreakpointType } from './types';

export const createMainUISlice: StoreSlice<MainUISlice> = (set) => ({
  isGlobalLoading: false,
  setIsGlobalLoading: (isLoading: boolean) => set({ isGlobalLoading: isLoading }),

  theme: typedEnv.REACT_APP_SUPPORT_DARK_THEME
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
    : 'light',
  setTheme: (theme) => set({ theme: typedEnv.REACT_APP_SUPPORT_DARK_THEME ? theme : 'light' }),

  // NAV MENU
  navMobileOpen: false,
  setNavMobile: (navMobileOpen) => set({ navMobileOpen }),

  // FOR RESPONSIVE DETECTION
  currentBreakpointDevice: {
    deviceType: DeviceBreakpointType.UNKNOWN,
    isMobile: false,
    isDesktop: false,
    isTablet: false,
  },
  updateBreakpoint: (deviceType) =>
    set({
      currentBreakpointDevice: {
        deviceType,
        isDesktop: deviceType === DeviceBreakpointType.DESKTOP,
        isMobile: deviceType === DeviceBreakpointType.PHONE,
        isTablet: deviceType === DeviceBreakpointType.TABLET,
      },
    }),
});
