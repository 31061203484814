import { EAuthMethod } from '@app/auth/constant';
import { InferType, object } from 'yup';
import { oneOfEnum } from '../utils/yup.utils';

const EnvOverrideSchema = object({
  REACT_APP_AUTH_METHOD: oneOfEnum(EAuthMethod).optional(),
});
type EnvOverrideSchema = InferType<typeof EnvOverrideSchema>;

const ENV_OVERRIDE_KEY = 'env_override';

// purpose of this class only partially override env
// for now, only for auth method for dev purposes
class EnvOverride {
  state: EnvOverrideSchema = {};

  constructor() {
    const maybeOverride = this.load();
    if (!maybeOverride) {
      return;
    }
    try {
      const parsedOverride = JSON.parse(maybeOverride);
      this.state = EnvOverrideSchema.cast(parsedOverride);
    } catch {
      this.cleanup();
    }
  }

  load() {
    return window.localStorage.getItem(ENV_OVERRIDE_KEY);
  }

  persist(newState: EnvOverrideSchema) {
    window.localStorage.setItem(ENV_OVERRIDE_KEY, JSON.stringify(newState));
  }

  cleanup() {
    window.localStorage.removeItem(ENV_OVERRIDE_KEY);
  }
}

export const envOverride = new EnvOverride();
