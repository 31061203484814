import { Routes } from '@app/constants/routes';

import { AuthStatus } from '@app/auth/api/auth.type';
import { MyRouteObject } from '@app/models/router';

import { AuthModule } from './AuthModule';
import { SignIn } from './pages/SignIn.page';
import { TypedNavigate } from '@app/router';

export const AuthRoutes: MyRouteObject = {
  path: Routes.auth.index,
  element: <AuthModule />,
  authStatus: AuthStatus.UNAUTHORIZED,
  children: [
    { path: Routes.auth.sign_in, element: <SignIn /> },
    // { path: Routes.auth.sign_up, element: <SignUp /> },
    // { path: Routes.auth.forgot_password, element: <ForgotPasswordPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.auth.sign_in} /> },
  ],
};
