import styles from './AdminModule.module.scss';
import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';

import { Header } from '@app/layout/header/Header';

export const AdminModule = () => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles['app-content']}>
        <main className={clsxm(styles.main)}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};
