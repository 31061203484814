import { apiService } from '@app/services/api-service';
import { PageResponseActionLogOutDto } from '@app/swagger-types';
import { createQueryString } from '@app/utils/api.util';
import { APIQueryParams } from '@app/models/api';

export class ActionLogsApi {
  static getActionLogs(params: APIQueryParams) {
    return apiService.get<PageResponseActionLogOutDto>(`/private/action-logs${createQueryString(params)}`);
  }
  static getGoogleSheets() {
    return apiService.get('/private/google-sheets');
  }
  static getGoogleSheetUrl() {
    return apiService.get<string>('/private/google-sheets/url');
  }
}
