import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';

import { RootModule } from './RootModule';
import { AdminRoutes } from './admin/AdminRoutes';
import { AuthRoutes } from './auth/AuthRoutes';
// import { NotFoundPage } from './root-pages/NotFound';
import { TypedNavigate } from '@app/router';
// import { RoadmapRoutes } from './roadmap/RoadmapRoutes';
// import { UIKitRoutes } from './ui-kit/UIKitRoutes';
// import { DevKitRoutes } from './dev-kit/DevKitRoutes';

export const RootRoutes: MyRouteObject[] = [
  {
    path: Routes.index,
    element: <RootModule />,
    children: [
      AdminRoutes,
      AuthRoutes,
      // UserRoutes, RoadmapRoutes, UIKitRoutes, DevKitRoutes
    ],
  },
  // IF YOU PREFER 404 ROOT PAGES => (require manual redirect after sign-in/sign-up/logout in responding pages/actions)
  // { path: '*', element: <NotFoundPage /> },
  { index: true, path: '*', element: <TypedNavigate to={Routes.index} /> },
];
