import { Outlet } from 'react-router-dom';

import { useStore } from '@app/store/useStore.hook';

import { Typography } from '@mui/material';

import AuthBackgroundImage from './assets/auth-background.png';
import { ReactComponent as Logo } from '@app/assets/images/app-logo.svg';
import { typedEnv } from '@app/environment/typed-env';
import { clsxm } from '@app/styles/clsxm';

export const AuthModule = () => {
  const theme = useStore.useTheme();

  return (
    <div className="flex min-h-screen flex-row">
      <div
        className="absolute h-full w-full overflow-hidden bg-cover bg-left-top bg-no-repeat"
        style={{
          backgroundImage:
            theme === 'light'
              ? `url(${AuthBackgroundImage})`
              : // TODO use other image for dark theme
                'url(${AuthBackgroundImage})',
        }}
      />
      <div className={`z-20 flex-1`}>
        <div className="absolute left-12 top-10 flex items-center space-x-3 ">
          <Logo />
          <Typography variant="xl">{typedEnv.REACT_APP_TITLE}</Typography>
        </div>
      </div>
      <div
        className={clsxm('z-10 flex basis-full items-center justify-center bg-white pt-24 pb-16', 'md:basis-[500px]')}
      >
        <div className="w-full max-w-sm p-8 py-16">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
