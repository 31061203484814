export const Routes = {
  index: '/',
  admin: {
    index: '/admin',
    dashboard: 'dashboard',
    test_page: '/admin/test-page',
    user_management: {
      index: '/admin/user-management',
      users: '/admin/user-management/users',
      admins: '/admin/user-management/admins',
      admin_profile: '/admin/user-management/admins/:id',
    },
    settings: '/admin/settings',
  },
  auth: {
    index: '/auth',
    sign_up: '/auth/sign-up',
    sign_in: '/auth/sign-in',
    forgot_password: '/auth/forgot-password',
    reset_password: '/auth/reset-password',
  },
  roadmap: {
    index: '/roadmap',
  },
  uiKit: {
    index: '/ui-kit',
  },
  devKit: {
    index: '/dev-kit',
  },
  sample: {
    index: '/sample',
  },
} as const;
