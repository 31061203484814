import { Outlet, useMatch } from 'react-router-dom';

import { Routes } from '@app/constants/routes';

import { AuthStatus } from '@app/auth/api/auth.type';

import { useStore } from '@app/store/useStore.hook';

import { isAdmin } from '@app/auth/auth.utils';

import { TypedNavigate } from '@app/router';

export const RootModule: React.FC = () => {
  const authStatus = useStore.useAuthStatus();
  const currentUser = useStore.useCurrentUser();
  const isRoot = useMatch(Routes.index);

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  if (authStatus === AuthStatus.UNAUTHORIZED && isRoot) {
    return <TypedNavigate to={Routes.auth.index} />;
  }

  if (authStatus === AuthStatus.SIGNED_IN && currentUser) {
    const rootRedirect = isAdmin(currentUser) ? Routes.admin.index : undefined;
    if (isRoot && rootRedirect) {
      return <TypedNavigate to={rootRedirect} />;
    }
  }

  return <Outlet />;
};
