import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { AdminModule } from './AdminModule';
import { Dashboard } from './pages/Dashboard';

export const AdminRoutes: MyRouteObject = {
  path: Routes.admin.index,
  element: <AdminModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.admin.dashboard, element: <Dashboard /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.dashboard} /> },
  ],
};
