import { EAuthMethod } from '@app/auth/constant';
import { boolean, object, string } from 'yup';
import { oneOfEnum } from '../utils/yup.utils';
import { envOverride } from './env-override';

const EnvSchema = object({
  REACT_APP_TITLE: string().required(),
  REACT_APP_DESCRIPTION: string().required(),
  REACT_APP_ENV_NAME: string().required(),
  REACT_APP_API_BASE_URL: string().required(),
  NODE_ENV: string().required(),
  REACT_APP_AUTH_METHOD: oneOfEnum(EAuthMethod).required(),
  REACT_APP_SUPPORT_DARK_THEME: boolean().default(false),
});

export const typedEnv = EnvSchema.cast(
  {
    ...process.env,
    ...(process.env.REACT_APP_ENV_NAME !== 'production' && envOverride.state),
  },
  { stripUnknown: true }
);

export const IS_AUTH_METHOD_COOKIE = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.COOKIE;
export const IS_AUTH_METHOD_TOKEN = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.TOKEN;

export const IS_PROD_ENV = typedEnv.REACT_APP_ENV_NAME === 'production';
export const IS_NOT_PROD_ENV = !IS_PROD_ENV;
