import { mapKeys } from 'lodash';
import { useCallback, useRef, useMemo } from 'react';
import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params';
import { useQueryParams, SetQuery, UrlUpdateType } from 'use-query-params';

interface Args<T, Q> {
  paramConfigMap: Q;
  prefix?: T;
}

export const useQueryParamsPrefix = <T extends string, Q extends QueryParamConfigMap>({
  paramConfigMap,
  prefix,
}: Args<T, Q>) => {
  const getConfig = useRef(() => {
    if (!prefix) {
      return paramConfigMap;
    }

    return mapKeys(paramConfigMap, function (_, key) {
      return `${prefix}_${key}`;
    });
  });

  const [query, setQuery] = useQueryParams(getConfig.current());

  const modifiedQuery = useMemo(() => {
    if (!prefix) {
      return query;
    }
    return mapKeys(query, (value, key) => {
      return key.replace(`${prefix}_`, '');
    });
  }, [prefix, query]);

  const handleSetQuery = useCallback(
    (
      changes:
        | Partial<DecodedValueMap<QueryParamConfigMap>>
        | ((latestValues: DecodedValueMap<QueryParamConfigMap>) => Partial<DecodedValueMap<QueryParamConfigMap>>),
      updateType?: UrlUpdateType
    ): void => {
      if (!prefix) {
        setQuery(changes, updateType);
        return;
      }

      const prefixedChanges = mapKeys(changes, (value, key) => {
        return `${prefix}_${key}`;
      });

      setQuery(prefixedChanges, updateType);
    },
    [prefix, setQuery]
  );

  return [modifiedQuery, handleSetQuery] as [DecodedValueMap<Q>, SetQuery<Q>];
};
