import React from 'react';

import { Avatar as MUIAvatar, AvatarProps as MuiAvatarProps } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { useBackgroundImageDownload } from '@app/hooks/useBackgroundImageDownload.hook';

export interface AvatarProps extends MuiAvatarProps {
  imageId?: string | null;
  src?: string;
  skeletonVariant?: 'circular' | 'rectangular' | 'text';
  skeletonAnimation?: 'pulse' | 'wave' | false;
  height?: number | string;
  width?: number | string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  imageId,
  src,
  height,
  width,
  skeletonVariant = 'circular',
  skeletonAnimation,
  className,
  children,
  style,
  ...rest
}) => {
  const [imageUrl, loading] = useBackgroundImageDownload(imageId);
  return (
    <>
      {loading && (
        <Skeleton
          className={className}
          animation={skeletonAnimation ?? 'wave'}
          variant={skeletonVariant}
          height={height}
          width={width}
        />
      )}
      {!loading && (
        <MUIAvatar
          className={className}
          src={src ?? imageUrl}
          style={{
            width,
            height,
            ...style,
          }}
          {...rest}
        >
          {children}
        </MUIAvatar>
      )}
    </>
  );
};
