import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { ActionLogsApi } from '@app/domain/action-logs/action-logs.api';
import { APIQueryParams } from '@app/models/api';
import { EQueryConfigNames } from '@app/api/query-config';
import { PageResponseActionLogOutDto } from '@app/swagger-types';

export const useGetActionLogs = (
  params: APIQueryParams,
  options?: UseQueryOptions<
    unknown,
    unknown,
    PageResponseActionLogOutDto,
    (typeof EQueryConfigNames.GET_ACTION_LOGS | APIQueryParams)[]
  >
) => {
  return useQuery(
    [EQueryConfigNames.GET_ACTION_LOGS, params],
    async () => {
      const { data } = await ActionLogsApi.getActionLogs(params);
      return data;
    },
    {
      ...options,
    }
  );
};

export const useGetGoogleSheetUrl = (
  options?: UseQueryOptions<unknown, unknown, string, typeof EQueryConfigNames.GET_GOOGLE_SHEET_URL[]>
) => {
  return useQuery(
    [EQueryConfigNames.GET_GOOGLE_SHEET_URL],
    async () => {
      const { data } = await ActionLogsApi.getGoogleSheetUrl();
      return data;
    },
    {
      ...options,
    }
  );
};

export const useGetGoogleSheets = (options: UseMutationOptions<unknown, unknown, void> = {}) => {
  return useMutation(async () => {
    const { data } = await ActionLogsApi.getGoogleSheets();
    return data;
  }, options);
};
