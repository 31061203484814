import { string, object, bool, date, InferType } from 'yup';

import { password, email } from '@app/utils/yup.utils';

export const SignInFormValidationSchema = object({
  email: email(),
  password: password(),
});
export type SignInFormValidationSchema = InferType<typeof SignInFormValidationSchema>;

export const SignUpFormValidationSchema = object({
  firstname: string().required('Required Field'),
  lastname: string().required('Required Field'),
  email: email(),
  password: password(),
  privacyPolicy: bool().oneOf([true], 'Accept Term And Privacy Policy').defined('Accept Term And Privacy Policy'),
  dateOfBirth: date().required('Required Field'),
});
export type SignUpFormValidationSchema = InferType<typeof SignUpFormValidationSchema>;

export const ConfirmCodeValidationSchema = object({
  confirmCode: string().required('Required Field'),
});
export type ConfirmCodeValidationSchema = InferType<typeof ConfirmCodeValidationSchema>;

export const ForgotPasswordValidationSchema = object({
  email: string().required('Required Field'),
});
export type ForgotPasswordValidationSchema = InferType<typeof ForgotPasswordValidationSchema>;

export const ConfirmPasswordValidationSchema = object({
  confirmCode: string().required('Required Field'),
  newPassword: password(),
});
export type ConfirmPasswordValidationSchema = InferType<typeof ConfirmPasswordValidationSchema>;
