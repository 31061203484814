import { toast } from 'react-toastify';
import { ValidationError as YupValidationError } from 'yup';

export enum ErrorName {
  APP_ERROR = 'AppError',
  API_ERROR = 'ApiError',
  API_VALIDATION_ERROR = 'ApiValidationError',
  API_FORBIDDEN_ERROR = 'ApiForbiddenError',
}

export type ErrorPayload = {
  message: string;
  field?: string;
};

export type ServerErrorData = {
  errors: ErrorPayload[];
  status: number;
  timestamp: string;
};

export type SerializedError = {
  errorType: ErrorName;
  errors: ErrorPayload[];
};

export abstract class CustomError extends Error {
  abstract errorType: string;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  abstract serializeError(): SerializedError;
}

export class AppError extends CustomError {
  errorType = ErrorName.APP_ERROR;
  constructor(public message: string) {
    super(message);
    Object.setPrototypeOf(this, AppError.prototype);
  }

  serializeError() {
    return {
      errorType: this.errorType,
      errors: [{ message: this.message }],
    };
  }
}

export class ApiError extends CustomError {
  errorType = ErrorName.API_ERROR;
  status = 500;
  timestamp = new Date().toISOString();
  errors: ErrorPayload[];

  constructor(errorData?: ServerErrorData, message?: string) {
    super(message || 'Api Error');
    this.status = errorData?.status || 500;
    this.errors = errorData?.errors || [{ message: message || 'Unexpected Api Error' }];
    this.timestamp = errorData?.timestamp || new Date().toISOString();
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  serializeError() {
    return { errorType: this.errorType, errors: this.errors };
  }
}

export class ApiValidationError extends ApiError {
  errorType = ErrorName.API_VALIDATION_ERROR;
  status = 400;
  constructor(errorData?: ServerErrorData, message?: string) {
    super(errorData, message || 'Validation Error');
    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export class ApiForbiddenError extends ApiError {
  errorType = ErrorName.API_FORBIDDEN_ERROR;
  status = 403;
  constructor(errorData?: ServerErrorData, message?: string) {
    super(errorData, message || 'Forbidden Error');
    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export const handleError = (e: Error) => {
  if (e instanceof CustomError) {
    return e.serializeError();
  }

  if (e instanceof YupValidationError) {
    console.error(e.message);
    const error = new AppError(e.message);
    return error.serializeError();
  }

  const unknownAppError = new AppError(e.message || 'Unknown Application Error');
  return unknownAppError.serializeError();
};

export const toastifyValidationError = (e: Error | SerializedError) => {
  if ('errorType' in e) {
    if (e instanceof ApiValidationError || e.errorType === ErrorName.API_VALIDATION_ERROR) {
      toast.error(e.errors[0].message);
    }
    if (e instanceof ApiError) {
      e.errors.forEach((err) => toast.error(err.message));
    }
  }
};
