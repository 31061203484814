import { NestedKeyOf } from './type.utils';

export const buildSortParam = <T extends object>(field: NestedKeyOf<T>, mode: 'desc' | 'asc') => `${field},${mode}`;

export type QueryParam = {
  [k: string]: string | number | any;
};

export const createQueryString = (...params: QueryParam[]) => {
  if (!params.length) {
    return '';
  }

  return params
    .reduce((res, curr) => {
      const currentObjectParams = Object.entries(curr).reduce((res, [key, value]) => {
        if (!value && value !== 0 && typeof value !== 'boolean') {
          return res;
        }
        return `${res}${key}=${encodeURI(value)}&`;
      }, '');
      return res + currentObjectParams;
    }, '?')
    .slice(0, -1);
};
