import styles from './UserHeaderInfo.module.scss';
import { capitalize } from 'lodash';
import { useState, useMemo } from 'react';

import { useQueryClient } from 'react-query';

import { AuthUser } from '@app/auth/api/auth.dto';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material/';
import { Typography, Menu, MenuItem } from '@mui/material';

import { ConditionalWrapper } from '@app/hoc';
import { Button, Avatar } from '@app/components';

type InjectedProps = {
  user: AuthUser;
  isMobileLayout?: boolean;
};

export const UserHeaderInfo: React.FC<InjectedProps> = ({ user, isMobileLayout }) => {
  const queryClient = useQueryClient();
  const { signOut } = useStore();

  const [firstName, lastName] = useMemo(() => {
    const { firstName, lastName } = user;

    return [capitalize(firstName), capitalize(lastName.substring(0, 1))];
  }, [user]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    queryClient.removeQueries();

    //   replace({ path: Routes.SIGN_IN }); IF 404 solution used
  };

  return (
    <div className={styles.Container} data-cy="user-header-avatar">
      <Menu
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem onClick={handleLogout} data-cy="sign-out-button">
          Sign out
        </MenuItem>
      </Menu>
      <Button
        variant="text"
        disableRipple
        className={clsxm(styles.MenuButton, 'space-x-2')}
        endIcon={
          anchorEl ? <ArrowDropUp className={styles.ButtonIcon} /> : <ArrowDropDown className={styles.ButtonIcon} />
        }
        onClick={handleClick}
      >
        <ConditionalWrapper
          condition={!!isMobileLayout}
          wrapper={(children) => {
            return <div className={styles.NameGroup}>{children}</div>;
          }}
        >
          <Typography variant="m" noWrap className={styles.UserName}>
            {`${firstName} ${lastName}.`}
          </Typography>
        </ConditionalWrapper>
        <Avatar className={styles.Avatar} id="header-avatar-filter" width={40} height={40} />
      </Button>
    </div>
  );
};
