import styles from './Select.module.scss';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { Input, InputProps } from '@app/components';

export interface SelectProps extends Omit<MuiSelectProps, 'inputProps' | 'size'> {
  inputProps?: InputProps;
  size?: 'large' | 'medium' | 'small';
  withPlaceholder?: boolean;
}

export const Select: React.FC<SelectProps> = React.forwardRef<unknown, SelectProps>(
  ({ inputProps, children, disabled, MenuProps, className, withPlaceholder, size = 'medium', ...props }, ref) => {
    return (
      <MuiSelect
        input={<Input {...inputProps} />}
        variant={inputProps?.variant}
        disabled={disabled}
        MenuProps={{
          classes: { paper: clsx(props.multiple ? styles.SelectPaperMultiple : styles.SelectPaper, className) },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          elevation: 1,
          ...MenuProps,
        }}
        classes={{
          select: clsx(
            styles.Select,
            styles[capitalize(size)],
            withPlaceholder && props.value === '' ? styles.WithPlaceholder : null
          ),
          disabled: styles.Disabled,
        }}
        ref={ref}
        IconComponent={(props) => {
          return !disabled ? (
            <div className={clsx(styles.ArrowIconWrapper, styles[capitalize(size)])}>
              <div
                className={clsx(
                  inputProps?.variant === 'filled' ? styles.ArrowNoBg : null,
                  styles.ArrowIcon,
                  props.className.includes('MuiSelect-iconOpen') ? styles.Expanded : null,
                  styles[capitalize(size)]
                )}
              >
                <ChevronDownIcon />
              </div>
            </div>
          ) : null;
        }}
        {...props}
      >
        {children}
      </MuiSelect>
    );
  }
);
